@font-face {
  font-family: "Boelve";
  src: url("./fonts/Boelve-Bold.eot");
  src:
    url("./fonts/Boelve-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Boelve-Bold.woff2") format("woff2"),
    url("./fonts/Boelve-Bold.woff") format("woff"),
    url("./fonts/Boelve-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Boelve-Rubrik";
  src: url("./fonts/Boelve-Rubrik.eot");
  src:
    url("./fonts/Boelve-Rubrik.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Boelve-Rubrik.woff2") format("woff2"),
    url("./fonts/Boelve-Rubrik.woff") format("woff"),
    url("./fonts/Boelve-Rubrik.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Boelve";
  src: url("./fonts/Boelve-Medium.eot");
  src:
    url("./fonts/Boelve-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Boelve-Medium.woff2") format("woff2"),
    url("./fonts/Boelve-Medium.woff") format("woff"),
    url("./fonts/Boelve-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Boelve";
  src: url("./fonts/Boelve-Regular.eot");
  src:
    url("./fonts/Boelve-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Boelve-Regular.woff2") format("woff2"),
    url("./fonts/Boelve-Regular.woff") format("woff"),
    url("./fonts/Boelve-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
