@import "./fonts.scss";
@import "./cookieOverlay.scss";
@import "./Slider.scss";

$blue: #006bb6;
$darkblue: #002d57;
$ratio: 0.68/1; //4 / 5;

html,
body,
#root,
.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
  background: $blue;
  font-family: "Boelve";
  color: white;
  font-size: 30px;
  min-height: 100vh;
  &.Mobile {
    width: 100vw;
  }
}
a,
a:active,
a:hover {
  font-size: 22px;
  color: white;
}
.friendslink {
  font-size: 0.7rem;
  &:active,
  &:hover {
    font-size: 0.7rem;
  }
  text-decoration: none;
  padding-left: 40px;
  padding-right: 40px;

  span {
    text-decoration: underline;
  }
}
.dottHolder {
  position: absolute;
  margin: auto;
  bottom: -30px;
  display: flex;
  height: 20px;
  width: 100px;
  align-items: center;
  justify-content: space-evenly;
  .dott {
    width: 15px;
    height: 15px;
    background: #002d57;
    border-radius: 15px;
    transition:
      width 0.5s,
      color 0.5s;
    transform-origin: center;
    &.active {
      width: 30px;
      background: white;
    }
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  display: flex;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  .header-content {
    z-index: 10;
    display: flex;
    flex: 1;
    min-height: 100vh;
    width: 100%;
    .logos {
      position: absolute;
      top: 0;
      display: flex;
      width: calc(100% - 160px);
      height: 180px;
      align-items: center;
      justify-content: space-between;
      padding-left: 80px;
      .plogo {
        width: 100px;
        height: auto;
      }
      .flogo {
        width: 140px;
        height: auto;
      }
    }
    .title {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title-content {
        display: flex;
        flex-direction: column;
        //width: 960px;
        align-items: center;
        text-align: center;
        justify-content: center;
        img {
          height: auto;
          max-width: 500px;
        }
        h1 {
          font-family: "Boelve-Rubrik";
          font-size: 70px;
          line-height: 70px;
        }
      }
    }
  }
}
h2 {
  font-size: 0.8rem;
  line-height: 0.8rem;
  margin: 0;
}
.content {
  display: flex;
  flex: 1;
  background: $blue;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px 100px 15px;
  margin-top: -40px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  position: relative;
  z-index: 200;
  .contentHolder {
    z-index: 20;
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 100px;
    &.start {
      max-width: 960px;
      .lastBlock {
        position: absolute;
        bottom: 40px;
      }
    }
    .heart {
      margin: 30px;
      max-width: 200px;
    }
  }
}
.videoLoop {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .video-container {
    min-width: 100%;
    min-height: 100%;
    background-image: url("../assets/dbg.mp4");
    background-size: cover;
    background-position: center center;
    background-color: transparent;
    position: absolute;
    z-index: 1;
  }
  #bgVideo {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
  }
}

.btn {
  padding: 20px;
  border-radius: 20px;
  background: $darkblue;
  color: white;
  font-family: "Boelve-Rubrik";
  font-size: 0.8rem;
  &.start {
    margin-top: 10px;
    font-size: 0.7rem;
  }
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.bottVideoHolder {
  position: relative;
  display: flex;
  flex-grow: 1;
  &:after {
    content: "";
    clear: both;
  }
  .info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 400;
    p {
      margin-left: 10px;
      text-align: center;
    }
    img {
      width: 100px;
      margin-left: 10px;
    }
  }
}

.create {
  background: $blue;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  position: relative;
  .controls {
    width: 100%;
    height: 40px;
    position: relative;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
    .close {
      position: absolute;
      font-weight: bold;
      right: 0px;
      font-size: 0.7rem;
      top: 10px;
      right: -10px;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background: white;
      }
    }
  }
  .steps {
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 300px;
    align-items: center;
    svg {
      width: 90%;
      margin-left: -10px;
      margin-bottom: 20px;
    }
    .labels {
      display: flex;
      width: 100%;
      font-size: 0.4rem;
      justify-content: space-between;
      text-align: center;
      color: white;
      p {
        font-family: "Boelve";
        font-weight: bold;
        font-size: 0.6rem;
        line-height: 0.6rem;
        margin: unset;
      }
    }
  }
  .ccontent {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 0.8rem;
    img {
      margin-bottom: 0px;
    }
  }

  .s2content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    .playerHolder {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .play {
        width: 80px;
        height: 80px;
        display: block;
        position: absolute;
        z-index: 100;
        background-image: url("../assets/play.png");
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .stop {
        width: 100%;
        height: 100%;

        position: absolute;
        cursor: pointer;
        z-index: 110;
      }
      .player {
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .listMusic {
      display: flex;
      flex-direction: column;
      // flex-grow: 1;
      padding-bottom: 20px;
      ///flex: 1 1 auto;
      // overflow-y: auto;
      //justify-content: space-evenly;
      // &:-webkit-scrollbar {
      //   display:none;
      // }
      //  -ms-overflow-style: none;
      // scrollbar-width: none;

      .genre {
        font-family: "Boelve";
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 0.8rem;
        position: relative;
        margin-bottom: 5px;
        padding-top: 5px;
        box-sizing: border-box;
        .selectBox {
          width: 30px;
          height: 30px;
          display: inline-block;
          margin-right: 10px;
          position: relative;
          top: 5px;
          background-image: url("../assets/checkbox.svg");
          background-repeat: no-repeat;
          background-position: top left;
          &.selected {
            background-image: url("../assets/checkbox_checked.svg");
          }
        }
        &.selected {
          font-weight: bold;
          p {
            position: relative;
            &:after {
              content: "";
              background-image: url("../assets/check.png");
              background-repeat: no-repeat;
              background-size: cover;
              width: 19px;
              height: 23px;
              display: inline-block;
              position: absolute;
              margin-top: 0px;
              margin-left: 10px;
            }
          }
        }
        .g_info {
          cursor: pointer;
          display: flex;
          p {
            margin: 0;
          }
          flex-direction: row;
          justify-content: space-between;
          align-items: centers;
        }
        .line {
          width: 100%;
          background-image: url("../assets/line_lng.svg");
          background-repeat: no-repeat;

          background-size: cover;
          display: block;
          height: 8px;
          margin-top: 15px;
          margin-bottom: 0px;
        }
        .btnMusic {
          cursor: pointer;
          display: flex;
          width: 34px;
          height: 34px;
          background-image: url("../assets/stop.svg");
          background-repeat: no-repeat;
          background-size: cover;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
          &.playing {
            background-image: url("../assets/play.svg");
          }
        }
      }
    }
  }
  .foot {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;

    .next {
      position: unset;
    }
    .btn {
      cursor: pointer;
      position: unset !important;
      bottom: 0px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      &.disabled {
        opacity: 0.5;
      }
    }
  }
  .stepHolder {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .step {
      overflow-x: hidden;
      display: flex;
      flex: 1;
      flex-direction: column;
      .genre {
        color: white;
      }
      &._1 {
        .foot {
          justify-content: flex-end;
        }
      }
      &._3 {
        .ccontent {
          margin-top: 0px;

          p {
            margin-bottom: 10px;
          }
        }
        .s2content {
          flex: 1;
          justify-content: space-between;
        }
        .foot {
          flex: 0.3;
          flex-direction: column;
          margin-bottom: 0px;

          .btn {
            white-space: pre;
          }
          .btnRow {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            justify-content: space-evenly;

            margin-bottom: 20px;
            gap: 10px;
            .btn {
              position: unset;
            }
            &.social {
              justify-content: center;
              gap: 10px;
            }
            .social {
              width: 25px;
              height: 25px;
              display: block;
            }
          }
        }
      }
    }
    .btn {
      display: flex;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 10px;
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
  .blankBtn {
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;
    display: flex;
    flex-direction: column;
    &.pickname {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1rem;
    }
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: white;
    }
  }
  .next {
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    padding: 10px 20px;
    &.back {
      transform: scaleX(-1);
      right: unset;
      left: 20px;
    }
    &.disabled {
      opacity: 0.4;
    }
  }
}
.Desk {
  .btn {
    &.start {
      margin-top: 50px;
    }
  }
  .header {
    .header-content {
      .title-content {
        img {
          height: 270px;
        }
        h1 {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }
  }

  .content {
    margin-top: -50px;
    padding-top: 90px;
    h2 {
      font-size: 45px;
      line-height: 45px;
    }
    p {
      font-size: 22px;
      line-height: 24px;
    }
  }

  @media all and (-ms-high-contrast: none) {
    .content {
      margin-top: -20px;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .content {
      margin-top: -20px;
    }
  }
  @supports (-ms-accelerator: auto) {
    .content {
      margin-top: -20px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .content {
      margin-top: -20px;
    }
  }

  .create {
    .stepHolder {
      max-width: 450px;
      &._3 {
        max-width: 1024px;
        width: 1000px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .step {
          &._3 {
            max-width: 540px;
          }
        }
        .playerHolder {
          position: relative;
          margin-left: 20px;
          width: 300px;

          border-radius: 20px;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          .player {
            margin-bottom: -10px;
          }
          .play {
            width: 80px;
            height: 80px;
            display: block;
            position: absolute;
            z-index: 100;
            background-image: url("../assets/play.png");
            background-size: cover;
            background-repeat: no-repeat;
            cursor: pointer;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0px);
          }
          .stop {
            width: 100%;
            height: 100%;
            position: absolute;
            cursor: pointer;
            z-index: 110;
          }
        }
      }
    }
  }
  .overlay {
    .overlayContent {
      max-width: 400px;
    }
  }
}
.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: fill-available;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  .overlayContent {
    margin: 40px 30px;
    position: relative;
    display: flex;
    width: 100%;
    height: 80%;
    max-width: 500px;
    max-height: calc(100% - 60px);
    background: $blue;
    color: white;
    flex-direction: column;
    &.copy {
      height: auto;
      width: 100%;
      max-width: 800px;
      padding: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      .copied {
        opacity: 0;
        position: absolute;
        bottom: 40px;
      }
      .copyHolder {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .btn {
        cursor: pointer;
        &:active {
          background: black;
        }
      }
      img {
        max-width: 300px;
      }
      p {
        font-size: 0.8rem;
        line-height: 0.9rem;
        margin-bottom: 0;
      }
      .link {
        font-weight: bold;
        font-size: 0.8rem;
        line-height: 0.9rem;
        display: block;
        margin: 40px 0px;
      }
    }
    .close {
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      padding: 10px 15px;
      padding-right: 10px;
    }
    .btn {
      display: flex;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 10px;
    }
    .search,
    .list,
    .note {
      display: flex;
      flex-direction: column;
      padding: 0px 40px;
      color: white;
    }
    .search {
      background: $blue;
      flex: 0.2;
      min-height: 100px;
      padding-top: 10px;
      justify-content: center;
      h2 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .inputholder {
        position: relative;
        display: flex;
        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 22px;
          background-image: url("../assets/glass.png");
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 4px;
          left: 12px;
        }
      }
      .searchField {
        width: 100%;
        border-radius: 30px;
        height: 30px;
        padding-left: 40px;
        padding-right: 10px;
        border: unset;
        background-color: #efefef;
        font-family: "Boelve";
        position: relative;
        &:focus {
          outline: none;
        }
      }
    }
    .list {
      flex-grow: 1;
      margin-top: 20px;
      padding-bottom: 80px;
      overflow-y: scroll;
      .listName {
        cursor: pointer;
        font-family: "Boelve";
        font-size: 0.8rem;
        line-height: 0.8rem;
        position: relative;
        margin-bottom: 10px;
        box-sizing: border-box;
        p {
          margin: 0;
          display: flex;
          position: relative;
          flex-direction: row;
        }
        &.selected {
          display: flex;
          flex-direction: column;
          font-weight: bold;
          .check {
            background-image: url("../assets/check.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 19px;
            height: 23px;
            display: block;
            margin-left: 10px;
            margin-top: 0px;
          }
        }
        .line {
          width: 100%;
          background-image: url("../assets/line.svg");
          background-repeat: no-repeat;
          display: block;
          height: 8px;
          margin-top: 2px;
        }
      }
    }
    .note {
      flex: 0.3;
      background: $blue;
      padding-bottom: 10px;
      p {
        font-size: 0.5rem;
      }
      .blankBtn {
        display: inline-block;
        cursor: pointer;
        font-size: 0.7rem;
        font-weight: bold;
        line-height: 0.7rem;
        flex-direction: column;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 2px;
          background: white;
        }
      }
    }
  }
}

.Mobile {
  .create {
    .ccontent {
      font-size: 0.7rem !important;
      img {
        height: 70px;
      }
    }
  }
  .bottVideoHolder {
    .slider {
      overflow: hidden;
    }
  }
  .foot {
    .btn {
      font-size: 0.7rem;
    }
  }
  .videoLoop {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .video-container {
      min-width: 100%;
      aspect-ratio: $ratio;
      background-image: url("../assets/mbg.mp4");
    }
    #bgVideo {
      min-height: unset;
      height: 100%;
      min-width: 100%;
      aspect-ratio: $ratio;
    }
  }

  font-size: 0.7rem;
  .header {
    height: unset;
    min-height: unset;
    aspect-ratio: $ratio;
    .header-content {
      height: unset;
      min-height: unset;
      aspect-ratio: $ratio;
      .logos {
        width: calc(100% - 40px);
        padding-left: 20px;
        height: 110px;
      }
      .plogo {
        width: 60px;
        height: auto;
      }
      .flogo {
        width: 90px;
        height: auto;
      }
    }
    .title {
      .title-content {
        width: 100%;
        h1 {
          font-family: "Boelve-Rubrik";
          font-size: 0.7rem;
          line-height: 0.7rem;
        }
        img {
          max-width: 70%;
          margin-left: 0;
        }
      }
    }
  }

  .contentHolder {
    width: 100%;

    p,
    b {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .overlay {
    .overlayContent {
      max-width: 90vw;
      padding: 40px 20px;
      .copyHolder {
        img {
          width: 250px;
        }
        .copied {
          bottom: 5px;
          font-size: 0.6rem;
        }
        p {
          font-size: 0.7rem;
        }
        .link {
          font-size: 0.4rem;
          word-break: break-all;
          margin: 20px 0px;
        }
      }
    }
  }
}
svg {
  transform: unset !important;
}
.Mobile {
  .plateHolder {
    width: 100%;

    .plate {
      font-size: 1rem;
      line-height: 1.6;
    }
  }
}
.plateHolder {
  margin: 40px;
  width: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &.custom {
    margin-bottom: 0px;
  }
  .plate {
    background-color: #002d57;
    padding: 0px 15px;
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 2.6rem;
    width: fit-content;
    position: relative;
    margin-bottom: -2px;
    transform: rotate(2deg);
    z-index: 11;
    &.back {
      transform: rotate(-1deg);
      z-index: 999;
    }
    &:after,
    &:before {
      content: "";
      background-color: #002d57;
      width: 10px;
      height: 100%;
      position: absolute;
      left: -5px;
      top: 0;
      z-index: 10;
      transform: skewX(-5deg);
    }
    &:after {
      left: unset;
      right: -5px;
    }
  }
}

.playerHolderShare {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .play {
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    z-index: 100;
    background-image: url("../assets/play.png");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .stop {
    width: 100%;
    height: 100%;

    position: absolute;
    cursor: pointer;
    z-index: 110;
  }
  .player {
    border-radius: 10px;
    overflow: hidden;
  }
}
