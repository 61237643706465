.slider {
  position: absolute;
  perspective: 1000px;
  width: 100vw;
  transform-style: preserve-3d;
  flex: 1;
  margin: 0 auto;

  .btns {
    position: relative;
    top: 500%;
    left: 0px;
    padding: 0px 20px;
    flex: 1;
    display: flex;
    z-index: 90;
    height: 70px;
    pointer-events: none;
    flex-direction: row;

    justify-content: space-between;
    .nextprev {
      pointer-events: auto;
      cursor: pointer;
      display: block;
      width: 70px;
      height: 70px;
    }
    &.bigbox {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: auto;

      .nextprev {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        width: 25%;
        height: 100%;
        outline: none;
        display: flex;

        align-items: flex-end;

        &:nth-child(1) {
          justify-content: flex-start;
        }
        &:nth-child(1) {
          justify-content: flex-end;
        }
        img {
          position: relative;
          max-width: 50px;
          bottom: 15%;
        }
      }
    }
  }
}
.bottVideoHolder {
  margin-bottom: 80px;
}
.bottVideo {
  transition:
    transform 500ms ease 0s,
    opacity 500ms ease 0s,
    visibility 500ms ease 0s;
  position: absolute;
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 230;
  &:after {
    content: "";
    clear: both;
  }
  .play {
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    z-index: 100;
    background-image: url("../assets/play.png");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .stop {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 110;
  }
  .player {
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    overflow: hidden;
    position: relative;
    z-index: 10;
    iframe {
      margin-bottom: -8px;
    }
  }
  .subholder {
    display: flex;
    position: absolute;
    z-index: 210;
    bottom: 40px;
    width: 90%;
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 1.2rem;
      line-height: 1rem;
    }
    p {
      text-align: center;
      font-size: 0.8rem;
      line-height: 0.9rem;
      font-weight: 900;
      margin: 0;
      margin-top: 10px;
    }
    .first {
      max-height: 45px;
    }
  }
}
.Desk {
  .bottVideoHolder {
    padding-bottom: 100px;
  }
  .bottVideo {
    .player {
      border-bottom-right-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }
}
